const $: typeof document.querySelectorAll =
	document.querySelectorAll.bind(document)
const getChar: typeof String.fromCharCode = String.fromCharCode.bind(String)

main()

function main() {
	for (const emailLink of $<HTMLAnchorElement>('.email')) {
		$on(
			emailLink,
			'mouseenter focus',
			() => {
				emailLink.href = [
					'ma',
					'ilto',
					getChar(58),
					'info',
					getChar(64),
					'csakegyhonlap',
					'.',
					'hu',
				].join('')
			},
			{ once: true },
		)
	}
	for (const telLink of $<HTMLAnchorElement>('.tel')) {
		$on(
			telLink,
			'mouseenter focus',
			() => {
				telLink.href = ['tel', getChar(58, 43), '36', '21', '2526', '717'].join(
					'',
				)
			},
			{ once: true },
		)
	}
	for (const topButton of $<HTMLButtonElement>('.fel')) {
		$on(topButton, 'click', () => {
			window.scroll(0, 0)
		})
	}
}

function $on(
	elem: Element,
	events: string,
	cb: (e: Event) => void,
	o?: boolean | AddEventListenerOptions,
): () => void {
	const es = events.split(/\s+/).filter(Boolean)
	for (const e of es) {
		elem.addEventListener(e, cb, o)
	}
	return () => {
		for (const e of es) {
			elem.removeEventListener(e, cb, o)
		}
	}
}

export {}
